import request from '@/utils/request'

//成药详情
export function getDrugListNew(query) {
  return request({
    url: `/admin/drug/cpmInfo/${query.id}`,
    method: 'get',

  })
}

//修改成药基本信息
export function editWDrugNew(query) {
  return request({
    url: '/admin/drug/cpmBasic/' + query.id,
    method: 'put',
    data: query
  })
}


//修改成药扩展信息
export function editWDrugNews(query) {
  return request({
    url: '/admin/drug/cpmExtra/' + query.id,
    method: 'put',
    data: query
  })
}

//三方药事详情
export function getPharmacysNew(query) {
  return request({
    url: `/admin/drug/pharmacys/${query.id}`,
    method: 'get',
    data: query
  })
}


//新增成药(新)
export function addcpmDrug(query) {
  return request({
    url: `/admin/drug/cpmDrug`,
    method: 'post',
    data: query
  })
}

//新增中药(新)
export function addTcDrug(query) {
  return request({
    url: `/admin/drug/addTcDrug`,
    method: 'post',
    data: query
  })
}




//药品列表
export function getDrug(query) {
  return request({
    url: `/admin/drug`,
    method: 'get',
    params: query
  })
}

//药品列表
export function putStatus(id, enable_status) {
  return request({
    url: `/admin/drug/status/${id}`,
    method: 'put',
    data: { enable_status }
  })
}

//中药编辑(新)
export function editTCWDrugNew(query) {
  return request({
    url: '/admin/drug/updateTcDrug/' + query.id,
    method: 'put',
    data: query
  })
}
//机构选择
export function postAgentDoctor(query) {
  return request({
    url: '/admin/doctors/organization/bind/' + query.id,
    method: 'post',
    data: query
  })
}

// 获取表头
export function getAdminFieldIndex(params) {
  return request({
    url: 'admin/field/index',
    method: 'GET',
    params
  })
}
// 更新字段表信息
export function putAdminFieldIndex(data) {
  return request({
    url: 'admin/field/save',
    method: 'PUT',
    data
  })
}

// 照方抓药列表
export function getPresTakeList(params) {
  return request({
    url: `/admin/presTake`,
    method: 'get',
    params
  })
}
// 可选择剂型列表
export function getTcmDosage(params) {
  return request({
    url: `/admin/getTcmDosage`,
    method: 'get',
    params
  })
}
// 获取剂型煎法
export function getTcmFried(params) {
  return request({
    url: `/admin/getTcmFried`,
    method: 'get',
    params
  })
}

// 医生列表
export function getDoctorList(params) {
  return request({
    url: `/admin/getDoctorList`,
    method: 'get',
    params
  })
}

// 药材搜索
export function getDrugList(params) {
  return request({
    url: `/admin/getDrugs`,
    method: 'get',
    params
  })
}

// 开具处方
export function postAddPhotoDrug(id, data) {
  return request({
    url: `/admin/presTake/${id}`,
    method: 'post',
    data
  })
}
// 修改处方
export function postEditPhotoDrug(id, data) {
  return request({
    url: `/admin/presTake/${id}`,
    method: 'put',
    data
  })
}

// 获取处方详情(雨之堂)
export function getPresDetail(id) {
  return request({
    url: `admin/presDetail/${id}`,
    method: 'get'
  })
}

// 处方审核列表
export function getPresAuditList(params) {
  return request({
    url: `admin/presExamineList`,
    method: 'get',
    params
  })
}

// 处方审核
export function postAuditPres(id, data) {
  return request({
    url: `/admin/examinePres/${id}`,
    method: 'post',
    data
  })
}

// 诊断搜索
export function getDiseaseList(params) {
  return request({
    url: `admin/getDisease`,
    method: 'get',
    params
  })
}

// 获取用药禁忌和服药说明
export function getDrugTcm(params) {
  return request({
    url: `admin/getDrugTcm`,
    method: 'get',
    params
  })
}

// 加工费计算  admin/calcProcessCost
export function getDrugCalcProcessCost(params) {
  return request({
    url: `admin/calcProcessCost`,
    method: 'get',
    params
  })

}
// 修改剂型 获取中药药材
export function getDrugInfoByDosageId(data) {
  return request({
    url: `admin/getDrugInfo`,
    method: 'post',
    data
  })
}

// 删除照方抓药
export function delPresTake(params) {
  return request({
    url: `/admin/delPresTake`,
    method: 'delete',
    params
  })
}

export function getInnerCf(cf_no) {
  return request({
    url: `/admin/getInnerCf`,
    method: 'get',
    params:{cf_no}
  })
}


